import React from "react";

import practice2 from "../img/practice2.png";

const WhyInfo = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-full md:w-4/5 flex flex-col justify-center items-center py-16">
        <div className="w-full flex flex-row justify-center items-start flex-wrap mb-4">
          <div
            className="w-full md:w-1/3 flex justify-center items-start"
            style={{ minWidth: "258px" }}
          >
            <img alt="practice-2" src={practice2} />
          </div>
          <div className="flex flex-col justify-start items-center w-full px-4 sm:w-2/3 sm:px-0">
            <p className="text-gray-600 font-semibold text-md mb-2 text-center md:text-left">
              Our medical practice <i>specializes</i> in Internal Medicine
            </p>
            <p className="text-gray-600 text-md mb-4 text-center md:text-left">
              At SunState Medical clinic we strive to provide care that is
              convenient and meets your needs. We practice open-access
              scheduling, which means you should typically be able to schedule
              an appointment with us within 24 hours of your call.
            </p>
            <p className="text-gray-600 text-md mb-4 text-center md:text-left">
              We handle a wide spectrum of medical concerns, from the common
              cold to diabetes and heart disease (see Specialties). Our
              providers also provide regular preventative care visits such as
              physicals, pap smears and adult check-ups. Our practice uses
              electronic medical records.
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col justify-center items-start px-8">
          <p className="text-gray-600 font-semibold text-md mb-2">
            Internal Medicine goes <i>beyond</i> Primary Care
          </p>
          <p className="text-gray-600 text-md mb-4">
            SunState Medical clinic is a private practice group of three
            physicians specializing in Internal Medicine. Each of our physicians
            is board certified American Board of Internal Medicine, which means
            that you will get knowledgeable, up to date care for any medical
            concern you may have. Our physicians not only provide primary care,
            but are also adept at coordinating your care if you see multiple
            specialists or have several medical conditions. Depending on your
            individual situation, they can help you find the right specialist,
            set you up for a diagnostic testing, and coordinate various
            therapies. We believe in treating the whole person, not just a
            disease.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Whether a patient is in for an earache or in need of colon-cancer
            screening, primary-care providers deliver the basic diagnostic
            treatment and preventative medical care that patients most often
            need. They are the practitioners on the front lines of your care who
            respond to a broad range of medical problems for people throughout
            their lives. You primary-care provider is your partner in helping
            you stay well, treating you when you’re sick and referring you to a
            specialist when needed.
          </p>
          <p className="text-gray-600 text-md mb-4">
            Each of our physicians is privileged at local hospitals.
          </p>
          <p className="text-gray-600 font-semibold text-md mb-2">
            Testing on Location
          </p>
          <p className="text-gray-600 text-md mb-4">
            EKG, Ultrasound, skin surgeries and blood tests are performed
            onsite.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyInfo;
