import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import Conditions from "../../components/Conditions";
import Information from "../../components/Information";
import Map from "../../components/Map";
import WhyInfo from "../../components/WhyInfo";

const WhySunstate = () => {
  return (
    <Layout>
      <PageHeader text="Why Choose Sunstate Medical Associates" />
      <WhyInfo />
      <Conditions />
      <Information />
      <Map />
    </Layout>
  );
};

export default WhySunstate;
